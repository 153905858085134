<template>
  <div class="about">
    <div class="hero bg-grey w-full relative mdmax:px-4 overflow-hidden">
      <div class="shape-3 inline-block absolute top-0 -left-16 z-0 mdmax:hidden">
        <img src="@/assets/images/shape3.png" />
      </div>
      <div class="shape-1 inline-block absolute top-0 -right-14 z-0 mdmax:hidden">
        <img src="@/assets/images/shape1.png" />
      </div>
      <div class="container mx-auto py-16 mdmax:py-8">
        <div class="flex justify-between items-center gap-8 mdmax:flex-col">
          <div class="mdmax:w-full">
            <div class="text-46 mdmax:text-center font-extrabold text-neutral leading-tight mb-12 mdmax:text-2xl mdmax:mb-6">
              {{ $t('podcast.title1') }}
              <span class="text-yellow">TECHNOLOGEEK</span> {{ $t('podcast.title2') }}
            </div>
            <div v-if="Object.keys(latestPodcast).length > 0" class="mb-6 hero-iframe">
              <div v-if="Object.keys(latestPodcast).length > 0 && isMobile" class="mb-4">
                <iframe
                  style="border-radius: 12px"
                  :src="getEmbeddedURL(latestPodcast.uri)"
                  width="100%"
                  height="352"
                  frameBorder="0"
                  allowfullscreen=""
                  allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                  loading="lazy"
                ></iframe>
              </div>
            </div>
            <div class="inline-grid grid-flow-col gap-4 items-center mdmax:grid-flow-row mdmax:mb-0 mdmax:block">
              <div class="text-2xl font-medium text-neutral-500 mr-2 mdmax:text-lg mdmax:text-center mdmax:mb-2 mdmax:mr-0">
                {{ $t('podcast.subsOn') }}
              </div>
              <div class="inline-grid grid-flow-col gap-4 items-center mdmax:grid mdmax:grid-cols-3 mdmax:gap-2">
                <a href="https://www.youtube.com/@G2AcademyIndonesia" class="py-2 px-4 mdmax:px-2 mdmax:flex justify-center border rounded border-neutral-400" target="_blank">
                  <Youtube />
                </a>
                <a href="https://open.spotify.com/show/6oxZbsGu6YIMkAqiZy0mlK" class="py-2 px-4 mdmax:px-2 mdmax:flex justify-center border rounded border-neutral-400" target="_blank">
                  <Spotify />
                </a>
                <a href="https://open.noice.id/catalog/77453a0c-7e04-4b79-a699-5f30a65d0538" class="py-2 px-4 mdmax:px-2 mdmax:flex justify-center border rounded border-neutral-400" target="_blank">
                  <Noice />
                </a>
              </div>
            </div>
          </div>
          <div class="mdmax:w-full h-full mdmax:mb-5 podcast-image rounded-xl flex-shrink-0 mdmax:hidden">
            <template v-if="Object.keys(latestPodcast).length > 0">
              <iframe
                style="border-radius: 12px"
                :src="getEmbeddedURL(latestPodcast.uri)"
                width="100%"
                height="352"
                frameBorder="0"
                allowfullscreen=""
                allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                loading="lazy"
              ></iframe>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-white">
      <div class="container mx-auto py-8 mdmax:px-4">
        <div class="flex items-center mdmax:flex-col">
          <div class="w-5/12 mdmax:w-full mdmax:mb-8">
            <img src="@/assets/images/logo-technologeek.png" />
          </div>
          <div class="w-7/12 pl-8 mdmax:pl-0 mdmax:w-full">
            <div class="text-3xl font-bold text-neutral mb-6 mdmax:text-2xl mdmax:text-center">{{ $t('podcast.description.title') }}</div>
            <div class="text-neutral-500 text-lg mdmax:text-base mb-8">
              {{ $t('podcast.description.desc1') }}
            </div>
            <div class="text-neutral-500 text-lg mdmax:text-base">
              {{ $t('podcast.description.desc2') }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-white">
      <div class="container mx-auto py-8 mdmax:px-4">
        <div class="text-3xl font-bold text-neutral mb-12 text-center mdmax:text-2xl">{{ $t('podcast.lastest') }}</div>
        <div class="px-10 mb-4 mdmax:px-0" v-for="podcast in podcastList" :key="podcast.id">
          <iframe
            style="border-radius: 12px"
            :src="getEmbeddedURL(podcast.uri)"
            width="100%"
            height="152"
            frameBorder="0"
            allowfullscreen=""
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            loading="lazy"
          ></iframe>
        </div>
        <div class="flex justify-center items-center" id="load-more"></div>
        <div class="flex justify-center" v-if="isFetching">
          <Loader />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import QueryString from 'qs'
import { mapActions, mapGetters } from 'vuex'
export default {
  components: {
    Youtube: () => import(/* webpackChunkName: "Youtube" */ '@/components/icons/podcast/Youtube'),
    Spotify: () => import(/* webpackChunkName: "Spotify" */ '@/components/icons/podcast/Spotify'),
    Noice: () => import(/* webpackChunkName: "Noice" */ '@/components/icons/podcast/Noice'),
    Loader: () => import(/* webpackChunkName: "Loader" */ '@/components/loader/Loader')
  },
  data() {
    return {
      token: '',
      podcastList: [],
      totalPodcast: 0,
      latestPodcast: {},
      limit: 3,
      offset: 0,
      currentPage: 0,
      isFetching: false
    }
  },
  computed: {
    ...mapGetters('common', ['isMobile'])
  },
  created() {
    this.getToken()
  },
  mounted() {
    this.setupObserver()
  },
  methods: {
    ...mapActions('common', ['showLoading', 'hideLoading']),
    setupObserver() {
      const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (this.podcastList.length !== this.totalPodcast) {
              this.currentPage++
              this.offset = this.currentPage * this.limit
              this.isFetching = true
              this.getPodcastList()
            }
          }
        })
      })
      const targetElement = document.getElementById('load-more')
      observer.observe(targetElement)
    },
    getEmbeddedURL(uri) {
      const uriArr = uri.split(':')
      const episodeId = uriArr[2]
      return `https://open.spotify.com/embed/episode/${episodeId}?utm_source=generator&theme=0`
    },
    async getToken() {
      this.showLoading()
      const response = await fetch('https://accounts.spotify.com/api/token', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: QueryString.stringify({
          grant_type: 'client_credentials',
          client_id: process.env.VUE_APP_SPOTIFY_CLIENT_ID,
          client_secret: process.env.VUE_APP_SPOTIFY_CLIENT_SECRET
        })
      })
      const result = await response.json()
      this.token = result.access_token
      this.getPodcastList()
    },
    async getPodcastList() {
      // https://api.spotify.com/v1/shows/6oxZbsGu6YIMkAqiZy0mlK/episodes?limit=5&offset=0 6oxZbsGu6YIMkAqiZy0mlK
      const profile = await fetch(`https://api.spotify.com/v1/shows/6oxZbsGu6YIMkAqiZy0mlK/episodes?market=ID&limit=${this.limit}&offset=${this.offset}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.token
        }
      })
      const finalResult = await profile.json()
      this.podcastList = [...this.podcastList, ...finalResult.items]
      this.totalPodcast = finalResult.total
      this.latestPodcast = this.podcastList[0]
      this.isFetching = false
      this.hideLoading()
    }
  }
}
</script>
<style lang="scss" scoped>
.podcast-image {
  width: 540px;
  background: #ccc;
  // aspect-ratio: 1/1;
}
.hero-iframe {
  width: 600px;
  @media screen and (max-width: 767px) {
    width: 100%;
  }
}
</style>
